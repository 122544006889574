<template>
  <div :class="fullFeature?'jsmb-account-commentaires jsmb-account-information-tab':'jsmb-account-information-detail'">
    <div class="tabs" v-if="fullFeature">
      <ul>
        <li :class="userType==='stockeur'?'is-active':''" @click="userType='stockeur'"><a>Vue Stockeur</a></li>
        <li :class="userType==='skipper'?'is-active':''" @click="userType='skipper'"><a>Vue Skipper</a></li>
      </ul>
    </div>
    <h2 class="jsmb-title-detail is-size-4 has-text-text">Mes réservations</h2>
    <div v-if="userType==='stockeur'">
      <p v-if="reservationsStockeur.length === 0">Vous n'avez pas de réservation.</p>

      <div v-if="this.reservationsStockeur.wait !== undefined">
        <div class="columns"><strong>Réservations en attente de confirmation</strong></div>
        <div v-for="(reservation, index) in reservationsStockeur.wait" :key="'reservation-wait-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-info">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns is-vcentered">
                <div class="column has-text-weight-bold is-7 has-text-left-desktop has-text-centered-mobile">
                  Validation de la réservation :
                </div>
                <div  class="columns buttons" v-if="reservation.stripe">
                  <div class="column has-text-centered">
                    <a class="button is-small is-primary" style="margin-right: 0.2rem;" @click="acceptRefuseOrder(reservation, 'accept', 'wait')" :class="buttonValidLoad.wait ?'is-loading':''" :disabled="buttonValidLoad.wait?true:false">Valider la réservation</a>
                  </div>
                  <div class="column has-text-centered">
                    <a class="button is-small is-danger" @click="acceptRefuseOrder(reservation, 'refuse', 'wait')" :class="buttonCancelLoad.wait ?'is-loading':''" :disabled="buttonCancelLoad.wait?true:false">Refuser la réservation</a>
                  </div>
                </div>
                <div class="column has-text-centered" v-else>
                  Avant de pouvoir valider une réservation<br />vous devez compléter votre profil.
                </div>
              </div>
              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus et valider la reservation</a>
          </div>
        </div>
      </div>

      <div v-if="this.reservationsStockeur.wait_pay !== undefined">
        <div class="columns"><strong>Réservations en attente de paiement</strong></div>
        <div v-for="(reservation, index) in reservationsStockeur.wait_pay" :key="'reservation-wait-pay-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-info">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>

              <div class="columns is-vcentered">
                <div class="column has-text-weight-bold is-7 has-text-left-desktop has-text-centered-mobile">
                  Gérer la réservation :
                </div>
                <div class="column has-text-centered">
                  <a @click="acceptRefuseOrder(reservation, 'cancel', 'confirm')" class="button is-small is-danger" :class="buttonCancelLoad.waitPay ?'is-loading':''" :disabled="buttonCancelLoad.waitPay?true:false"><i class="fas fa-window-close" style="margin-right: 0.5rem;"></i> Annuler</a>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus</a>
          </div>
        </div>
      </div>

      <div v-if="this.reservationsStockeur.confirm !== undefined">
        <div class="columns"><strong>Réservations Confirmées</strong></div>
        <div v-for="(reservation, index) in reservationsStockeur.confirm" :key="'reservation-confirm-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-primary">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns is-multiline has-text-left-desktop has-text-centered-mobile" v-if="reservation.invoices">
                <div class="column add-margin-bottom is-12 has-text-weight-bold">
                  Factures disponibles
                </div>
                <div class="column is-12">
                  <b-table 
                    :data="reservation.invoices"
                    paginated
                    per-page="5">
                    <template slot-scope="props">
                      <b-table-column field="id" label="N°" width="60" numeric>
                        {{ props.row.invoice_number }}
                      </b-table-column>

                      <b-table-column field="date_debut" label="Date début">
                        {{ props.row.date_begin }}
                      </b-table-column>

                      <b-table-column field="date_fin" label="Date Fin">
                        {{ props.row.date_end }}
                      </b-table-column>

                      <b-table-column field="ht" label="Prix HT">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ht) }}
                      </b-table-column>

                      <b-table-column field="tva" label="TVA">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_tva) }}
                      </b-table-column>

                      <b-table-column field="ttc" label="Prix TTC">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ttc) }}
                      </b-table-column>

                      <b-table-column field="status" label="Status">
                        <span class="tag" :class="props.row.status_slug==='paid'?'is-primary':props.row.status_slug==='unpaid'?'is-danger':'is-info'" >{{ props.row.status }}</span>
                      </b-table-column>

                      <b-table-column field="action" label="Action">
                        <a v-if="props.row.status_slug!=='wait'" class="button is-small" @click="actionInvoice(props.row)" :class="props.row.status_slug==='paid'?'is-info':props.row.status_slug==='unpaid'?'is-warning':''">
                          <span v-if="props.row.status_slug==='paid'">Télécharger</span>
                          <span v-else-if="props.row.status_slug==='unpaid'">A payer</span>
                        </a>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon
                              icon="emoticon-sad"
                              size="is-large">
                            </b-icon>
                          </p>
                          <p>Pas de factures.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </div>
              </div>

              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>

              <div class="columns is-vcentered">
                <div class="column has-text-weight-bold is-7 has-text-left-desktop has-text-centered-mobile">
                  Gérer la réservation :
                </div>
                <div class="column has-text-centered">
                  <a @click="Stripe_cancelOrder(reservation)" class="button is-small is-danger" :class="buttonCancelLoad.confirm ?'is-loading':''" :disabled="buttonCancelLoad.confirm?true:false"><i class="fas fa-window-close" style="margin-right: 0.5rem;"></i> Annuler</a>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus</a>
          </div>
        </div>
      </div>
      
      <div v-if="this.reservationsStockeur.terminate !== undefined">
        <div class="columns"><strong>Réservations Passées</strong></div>
        <div v-for="(reservation, index) in reservationsStockeur.terminate" :key="'reservation-confirm-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-dark">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns is-multiline has-text-left-desktop has-text-centered-mobile" v-if="reservation.invoices">
                <div class="column add-margin-bottom is-12 has-text-weight-bold">
                  Factures disponibles
                </div>
                <div class="column is-12">
                  <b-table 
                    :data="reservation.invoices"
                    paginated
                    per-page="5">
                    <template slot-scope="props">
                      <b-table-column field="id" label="N°" width="60" numeric>
                        {{ props.row.invoice_number }}
                      </b-table-column>

                      <b-table-column field="date_debut" label="Date début">
                        {{ props.row.date_begin }}
                      </b-table-column>

                      <b-table-column field="date_fin" label="Date Fin">
                        {{ props.row.date_end }}
                      </b-table-column>

                      <b-table-column field="ht" label="Prix HT">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ht) }}
                      </b-table-column>

                      <b-table-column field="tva" label="TVA">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_tva) }}
                      </b-table-column>

                      <b-table-column field="ttc" label="Prix TTC">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ttc) }}
                      </b-table-column>

                      <b-table-column field="status" label="Status">
                        <span class="tag" :class="props.row.status_slug==='paid'?'is-primary':props.row.status_slug==='unpaid'?'is-danger':'is-info'" >{{ props.row.status }}</span>
                      </b-table-column>

                      <b-table-column field="action" label="Action">
                        <a v-if="props.row.status_slug!=='wait'" class="button is-small" @click="actionInvoice(props.row)" :class="props.row.status_slug==='paid'?'is-info':props.row.status_slug==='unpaid'?'is-warning':''">
                          <span v-if="props.row.status_slug==='paid'">Télécharger</span>
                          <span v-else-if="props.row.status_slug==='unpaid'">A payer</span>
                        </a>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon
                              icon="emoticon-sad"
                              size="is-large">
                            </b-icon>
                          </p>
                          <p>Pas de factures.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </div>
              </div>

              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus</a>
          </div>
        </div>
      </div>

      <div v-if="this.reservationsStockeur.cancel !== undefined">
        <div class="columns"><strong>Réservations Annulées</strong></div>
        <div v-for="(reservation, index) in reservationsStockeur.cancel" :key="'reservation-cancel-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-warning">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade" v-if="reservation.invoices || reservation.discussion_id">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns is-multiline has-text-left-desktop has-text-centered-mobile" v-if="reservation.invoices">
                <div class="column add-margin-bottom is-12 has-text-weight-bold">
                  Factures disponibles
                </div>
                <div class="column is-12">
                  <b-table 
                    :data="reservation.invoices"
                    paginated
                    per-page="5">
                    <template slot-scope="props">
                      <b-table-column field="id" label="N°" width="60" numeric>
                        {{ props.row.invoice_number }}
                      </b-table-column>

                      <b-table-column field="date_debut" label="Date début">
                        {{ props.row.date_begin }}
                      </b-table-column>

                      <b-table-column field="date_fin" label="Date Fin">
                        {{ props.row.date_end }}
                      </b-table-column>

                      <b-table-column field="ht" label="Prix HT">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ht) }}
                      </b-table-column>

                      <b-table-column field="tva" label="TVA">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_tva) }}
                      </b-table-column>

                      <b-table-column field="ttc" label="Prix TTC">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ttc) }}
                      </b-table-column>

                      <b-table-column field="status" label="Status">
                        <span class="tag" :class="props.row.status_slug==='paid'?'is-primary':props.row.status_slug==='unpaid'?'is-danger':'is-info'" >{{ props.row.status }}</span>
                      </b-table-column>

                      <b-table-column field="action" label="Action">
                        <a v-if="props.row.status_slug!=='wait'" class="button is-small" @click="actionInvoice(props.row)" :class="props.row.status_slug==='paid'?'is-info':props.row.status_slug==='unpaid'?'is-warning':''">
                          <span v-if="props.row.status_slug==='paid'">Télécharger</span>
                          <span v-else-if="props.row.status_slug==='unpaid'">A payer</span>
                        </a>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon
                              icon="emoticon-sad"
                              size="is-large">
                            </b-icon>
                          </p>
                          <p>Pas de factures.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce" v-if="reservation.invoices || reservation.discussion_id">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="userType==='skipper'">
      <p v-if="reservationsSkipper.length === 0">Vous n'avez pas de réservation.</p>
      
      <div v-if="this.reservationsSkipper.wait !== undefined">
        <div class="columns"><strong>Réservations en attente de confirmation</strong></div>
        <div v-for="(reservation, index) in reservationsSkipper.wait" :key="'reservation-wait-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-info">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>

              <div class="columns is-vcentered">
                <div class="column has-text-weight-bold is-7 has-text-left-desktop has-text-centered-mobile">
                  Gérer ma réservation :
                </div>
                <div class="column has-text-centered">
                  <a @click="acceptRefuseOrder(reservation, 'cancel', 'wait')" class="button is-small is-danger" :class="buttonCancelLoad.wait ?'is-loading':''" :disabled="buttonCancelLoad.wait?true:false"><i class="fas fa-window-close" style="margin-right: 0.5rem;"></i> Annuler</a>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus</a>
          </div>
        </div>
      </div>

      <div v-if="this.reservationsSkipper.wait_pay !== undefined">
        <div class="columns"><strong>Réservations en attente de paiement</strong></div>
        <div v-for="(reservation, index) in reservationsSkipper.wait_pay" :key="'reservation-wait-pay-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-info">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>

              <div class="columns is-vcentered">
                <div class="column has-text-weight-bold is-7 has-text-left-desktop has-text-centered-mobile">
                  Gérer ma réservation :
                </div>
                <div class="column has-text-centered" style="margin-top: 1rem;">
                  <a @click="Stripe_initFormCard(reservation)" class="button is-small is-primary" style="margin-right: 3rem;"><i class="fas fa-credit-card" style="margin-right: 0.5rem;"></i> Payer</a>
                  <a @click="Stripe_cancelOrder(reservation)" class="button is-small is-danger" :class="buttonCancelLoad.waitPay ?'is-loading':''" :disabled="buttonCancelLoad.waitPay?true:false"><i class="fas fa-window-close" style="margin-right: 0.5rem;"></i> Annuler</a>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus et payer la reservation</a>
          </div>
        </div>
      </div>

      <div v-if="this.reservationsSkipper.confirm !== undefined">
        <div class="columns"><strong>Réservations Confirmées</strong></div>
        <div v-for="(reservation, index) in reservationsSkipper.confirm" :key="'reservation-confirm-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-primary">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns is-multiline has-text-left-desktop has-text-centered-mobile" v-if="reservation.invoices">
                <div class="column add-margin-bottom is-12 has-text-weight-bold">
                  Factures disponibles
                </div>
                <div class="column is-12">
                  <b-table 
                    :data="reservation.invoices"
                    paginated
                    per-page="5">
                    <template slot-scope="props">
                      <b-table-column field="id" label="N°" width="60" numeric>
                        {{ props.row.invoice_number }}
                      </b-table-column>

                      <b-table-column field="date_debut" label="Date début">
                        {{ props.row.date_begin }}
                      </b-table-column>

                      <b-table-column field="date_fin" label="Date Fin">
                        {{ props.row.date_end }}
                      </b-table-column>

                      <b-table-column field="ht" label="Prix HT">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ht) }}
                      </b-table-column>

                      <b-table-column field="tva" label="TVA">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_tva) }}
                      </b-table-column>

                      <b-table-column field="ttc" label="Prix TTC">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ttc) }}
                      </b-table-column>

                      <b-table-column field="status" label="Status">
                        <span class="tag" :class="props.row.status_slug==='paid'?'is-primary':props.row.status_slug==='unpaid'?'is-danger':'is-info'" >{{ props.row.status }}</span>
                      </b-table-column>

                      <b-table-column field="action" label="Action">
                        <a v-if="props.row.status_slug!=='wait'" class="button is-small" @click="actionInvoice(props.row)" :class="props.row.status_slug==='paid'?'is-info':props.row.status_slug==='unpaid'?'is-warning':''">
                          <span v-if="props.row.status_slug==='paid'">Télécharger</span>
                          <span v-else-if="props.row.status_slug==='unpaid'">A payer</span>
                        </a>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon
                              icon="emoticon-sad"
                              size="is-large">
                            </b-icon>
                          </p>
                          <p>Pas de factures.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </div>
              </div>

              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>

              <div class="columns is-vcentered">
                <div class="column has-text-weight-bold is-7 has-text-left-desktop has-text-centered-mobile">
                  Gérer ma réservation :
                </div>
                <div class="column has-text-centered">
                  <a @click="Stripe_cancelOrder(reservation)" class="button is-small is-danger" :class="buttonCancelLoad.confirm ?'is-loading':''" :disabled="buttonCancelLoad.confirm?true:false"><i class="fas fa-window-close" style="margin-right: 0.5rem;"></i> Annuler</a>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus</a>
          </div>
        </div>
      </div>

      <div v-if="this.reservationsSkipper.terminate !== undefined">
        <div class="columns"><strong>Réservations Passées</strong></div>
        <div v-for="(reservation, index) in reservationsSkipper.terminate" :key="'reservation-terminate-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-dark">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade" v-if="reservation.invoices || reservation.discussion_id">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns is-multiline has-text-left-desktop has-text-centered-mobile" v-if="reservation.invoices">
                <div class="column add-margin-bottom is-12 has-text-weight-bold">
                  Factures disponibles
                </div>
                <div class="column is-12">
                  <b-table 
                    :data="reservation.invoices"
                    paginated
                    per-page="5">
                    <template slot-scope="props">
                      <b-table-column field="id" label="N°" width="60" numeric>
                        {{ props.row.invoice_number }}
                      </b-table-column>

                      <b-table-column field="date_debut" label="Date début">
                        {{ props.row.date_begin }}
                      </b-table-column>

                      <b-table-column field="date_fin" label="Date Fin">
                        {{ props.row.date_end }}
                      </b-table-column>

                      <b-table-column field="ht" label="Prix HT">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ht) }}
                      </b-table-column>

                      <b-table-column field="tva" label="TVA">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_tva) }}
                      </b-table-column>

                      <b-table-column field="ttc" label="Prix TTC">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ttc) }}
                      </b-table-column>

                      <b-table-column field="status" label="Status">
                        <span class="tag" :class="props.row.status_slug==='paid'?'is-primary':props.row.status_slug==='unpaid'?'is-danger':'is-info'" >{{ props.row.status }}</span>
                      </b-table-column>

                      <b-table-column field="action" label="Action">
                        <a v-if="props.row.status_slug!=='wait'" class="button is-small" @click="actionInvoice(props.row)" :class="props.row.status_slug==='paid'?'is-info':props.row.status_slug==='unpaid'?'is-warning':''">
                          <span v-if="props.row.status_slug==='paid'">Télécharger</span>
                          <span v-else-if="props.row.status_slug==='unpaid'">A payer</span>
                        </a>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon
                              icon="emoticon-sad"
                              size="is-large">
                            </b-icon>
                          </p>
                          <p>Pas de factures.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce" v-if="reservation.invoices || reservation.discussion_id">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus</a>
          </div>
        </div>
      </div>

      <div v-if="this.reservationsSkipper.cancel !== undefined">
        <div class="columns"><strong>Réservations Annulées</strong></div>
        <div v-for="(reservation, index) in reservationsSkipper.cancel" :key="'reservation-cancel-'+index">
          <div class="message-looper columns is-vcentered has-text-centered" :class="reservation.show ? 'looper-expanded' : '' ">
            <div class="column">
              <figure class="image is-48x48 is-image-centered">
                <img
                  :src="reservation.user.profil_picture!==null?reservation.user.profil_picture:profilPicture"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <small>{{ reservation.user.first_name }}</small>
            </div>
            <div class="column is-3">
              <small>Du {{reservation.date_begin}} au {{reservation.date_end}}</small>
            </div>
            <div class="column is-3">
              <small>{{reservation.announcement_title}} - {{reservation.space_title}} ({{reservation.surface}}m²)</small>
            </div>
            <div class="column is-3 has-text-centered">
              <div class="tags has-addons">
                <span v-if="reservation.nb_days" class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)}} / jour</span>
                <span v-else class="tag">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)}} / mois</span>
                <span class="tag is-warning">{{reservation.status}}</span >
              </div>
            </div>
          </div>
          <transition name="fade" v-if="reservation.invoices || reservation.discussion_id">
            <div class="reservation-expanded" v-if="reservation.show">
              <div class="columns is-multiline has-text-left-desktop has-text-centered-mobile" v-if="reservation.invoices">
                <div class="column add-margin-bottom is-12 has-text-weight-bold">
                  Factures disponibles
                </div>
                <div class="column is-12">
                  <b-table 
                    :data="reservation.invoices"
                    paginated
                    per-page="5">
                    <template slot-scope="props">
                      <b-table-column field="id" label="N°" width="60" numeric>
                        {{ props.row.invoice_number }}
                      </b-table-column>

                      <b-table-column field="date_debut" label="Date début">
                        {{ props.row.date_begin }}
                      </b-table-column>

                      <b-table-column field="date_fin" label="Date Fin">
                        {{ props.row.date_end }}
                      </b-table-column>

                      <b-table-column field="ht" label="Prix HT">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ht) }}
                      </b-table-column>

                      <b-table-column field="tva" label="TVA">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_tva) }}
                      </b-table-column>

                      <b-table-column field="ttc" label="Prix TTC">
                        {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.row.price_ttc) }}
                      </b-table-column>

                      <b-table-column field="status" label="Status">
                        <span class="tag" :class="props.row.status_slug==='paid'?'is-primary':props.row.status_slug==='unpaid'?'is-danger':'is-info'" >{{ props.row.status }}</span>
                      </b-table-column>

                      <b-table-column field="action" label="Action">
                        <a v-if="props.row.status_slug!=='wait'" class="button is-small" @click="actionInvoice(props.row)" :class="props.row.status_slug==='paid'?'is-info':props.row.status_slug==='unpaid'?'is-warning':''">
                          <span v-if="props.row.status_slug==='paid'">Télécharger</span>
                          <span v-else-if="props.row.status_slug==='unpaid'">A payer</span>
                        </a>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon
                              icon="emoticon-sad"
                              size="is-large">
                            </b-icon>
                          </p>
                          <p>Pas de factures.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="columns" v-if="reservation.discussion_id">
                <div class="column is-8 has-text-weight-bold add-margin-bottom">
                  Mes Messages liés à cette réservation
                </div>
                <div class="column is-4 has-text-centered-mobile">
                  <router-link :to="{name: 'MessageDetail', params: {messageId:reservation.discussion_id}}" tag="a" class="button is-small is-primary" push>
                    Voir la conversation
                  </router-link>
                </div>
              </div>
            </div>
          </transition>
          <div class="has-text-right btn-annonce" v-if="reservation.invoices || reservation.discussion_id">
            <a class="button is-small is-primary" v-on:click="reservation.show = !reservation.show">voir plus</a>
          </div>
        </div>
      </div>
    </div>

    <b-modal :active.sync="isCardModalActive">
      <form @submit.prevent="Stripe_addUpdateCard(card.orderId)">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">{{card.title}}</p>
          </header>
          <section class="modal-card-body">
              <p>{{card.desciption.day}}</p>
              <p>{{card.desciption.resume}} {{card.desciption.price}}</p>
              <br />
              <div id="jsmb-stripe-card">
                {{card.form}}
              </div>
              <p style="color: red;">{{card.error}}</p>
              <br />
              <p><input type="checkbox" required> J’ai lu et j’accepte les <router-link :to="{name: 'Cgv'}" tag="a" push>conditions de vente</router-link> de JeStockeMonBateau</p>
          </section>
          <footer class="modal-card-foot">
            <button type="button" class="button" @click="isCardModalActive=false">Annuler</button>
            <button type="submit" class="button is-primary" :class="card.buttonDoneLoad?'is-loading':''" :disabled="card.buttonDoneLoad?true:false">Payer</button>
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { GET_ORDER, SET_ORDER_STATUS, PAY_INVOICE, GET_SETTING, STRIPE_CANCEL_ORDER } from '@/store/modules/database/actions/database.actions'
import Separator from '@/components/template/ui/Separator.vue'
import { log } from 'util';
import { setTimeout } from 'timers';

export default {
  components: {
    Separator
  },
  name: 'reservations',
  data () {
    return {
      fullFeature: JSON.parse(localStorage.getItem('full-feature')),
      userType: localStorage.getItem('user-type'),
      commissionPercentageJSMB: 0,

      stripe: undefined,
      isCardModalActive: false,
      card: {
        title: '',
        desciption: {
          day: '',
          resume: '',
          price: '',
        },
        form: null,
        error: "",
        orderId: null,
        buttonDoneLoad: false,
        isCancel: false,
        priceCancel: '',
        client_secret: '',
        order_status_slug: '',
      },

      buttonCancelLoad: {
        wait: false,
        waitPay: false,
        confirm: false
      },
      buttonValidLoad: {
        wait: false
      },

      reservationsStockeur: [],
      reservationsSkipper: [],

      profilPicture: require('./../../../assets/profil-modal.png')
    }
  },
  created () {
    this.stripe = Stripe(this.$store.state.globalConstant.STRIPE_TOKEN_PUBLISHABLE)

    // Get Settings
    this.$store.dispatch(
      GET_SETTING, {key: "commission_percentage_jsmb"}
    ).then(
      resp => {
        this.commissionPercentageJSMB = resp.value
      }
    )
    
    // Get Order
    this.$store.dispatch(
      GET_ORDER, { userType: this.userType }
    ).then(
      resp => {
        this.getOrdersResult(resp)
      }
    )
  },
  methods: {
    getOrdersResult (resp) {
      switch (this.userType) {
        case 'skipper':
          this.reservationsSkipper = resp
          break;
      
        case 'stockeur':
          this.reservationsStockeur = resp
          break;
      }
    },
    acceptRefuseOrder (reservation, stats, currentStatus) {
      switch (stats) {
        case 'accept':
          this.buttonValidLoad.wait = true
          if (confirm("Vous êtes sur le point d'accepter de stocker le bateau du skipper "+reservation.user.first_name+" du "+reservation.date_begin+" au "+reservation.date_end)) {
            this.setOrderStatus(reservation.order_id, currentStatus, 'wait_pay')
          }
          break;
      
        case 'refuse':
          this.buttonCancelLoad.wait = true
          if (confirm("ATTENTION ! Vous allez refuser de stocker le bateau du skipper "+reservation.user.first_name+" du "+reservation.date_begin+" au "+reservation.date_end)) {
            this.setOrderStatus(reservation.order_id, currentStatus, 'cancel')
          }
          break;

        case 'cancel':
          this.buttonCancelLoad.waitPay = true
          if (confirm("ATTENTION ! Vous allez annuler la réservation du "+reservation.date_begin+" au "+reservation.date_end)) {
            this.setOrderStatus(reservation.order_id, currentStatus, 'cancel')
          }
          break;
      }
    },
    setOrderStatus (orderId, status, newStatus, cardToken = null, priceCancel = null) {
      // Set Order Status
      this.$store.dispatch(
        SET_ORDER_STATUS, { orderId: orderId, userType: this.userType, status: newStatus, cardToken: cardToken, priceCancel: priceCancel }
      ).then(
        resp => {
          this.isCardModalActive = false
          this.card.buttonDoneLoad = false
          this.buttonCancelLoad.wait = false
          this.buttonCancelLoad.waitPay = false
          this.buttonCancelLoad.confirm = false
          this.buttonValidLoad.wait = false
          switch (this.userType) {
            case 'skipper':
              this.$delete(this.reservationsSkipper[status], orderId)
              if (Object.keys(this.reservationsSkipper[status]).length === 0) {
                this.$delete(this.reservationsSkipper, status)
              }
              if (this.reservationsSkipper[newStatus] === undefined) {
                this.reservationsSkipper[newStatus] = {}
              }
              this.reservationsSkipper[newStatus][orderId] = resp
              break;

            case 'stockeur':
              this.$delete(this.reservationsStockeur[status], orderId)
              if (Object.keys(this.reservationsStockeur[status]).length === 0) {
                this.$delete(this.reservationsStockeur, status)
              }
              if (this.reservationsStockeur[newStatus] === undefined) {
                this.reservationsStockeur[newStatus] = {}
              }
              this.reservationsStockeur[newStatus][orderId] = resp
              break;
          }
          //document.location.reload(true);
        }
      )
    },
    Stripe_initFormCard(reservation, isCancel = false) {
      const elements = this.stripe.elements();
      var style = {
        base: {
          fontSize: '16px',
          color: "#32325d",
        }
      };
      this.isCardModalActive=true
      this.card.form = elements.create('card', {style: style})
      this.card.form.addEventListener('change', ({error}) => {
        this.card.error = ''
        if (error) {
          this.card.error = error.message;
        }
      });
      this.card.orderId = reservation.order_id
      this.card.isCancel = isCancel
      this.card.order_status_slug = reservation.status_slug
      if (!isCancel) {
        this.card.title = "Paiement de la reservation "+reservation.announcement_title+" - "+reservation.space_title+" ("+reservation.surface+"m²)"
        if (reservation.nb_months) {
          this.card.desciption.day = "Vous êtes sur le point de payer votre reservation pour "+reservation.nb_months+" mois"
          this.card.desciption.resume = "Pour "+new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*30)+" / mois"
          this.card.desciption.price = ""
        }else{
          this.card.desciption.day = "Vous êtes sur le point de payer votre reservation pour "+reservation.nb_days+" jours"
          this.card.desciption.resume = "Pour "+new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc)+" / jour"
          this.card.desciption.price = "soit un total de "+new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_per_day_ttc*reservation.nb_days)
        }
      }else{
        this.card.title = "Annulation de la reservation du "+reservation.date_begin+" au "+reservation.date_end
        this.card.desciption.day = "Pour toute annulation, les frais de fonctionnement de la plate-forme JeStockeMonBateau seront dus."
        this.card.desciption.resume = "Merci de procéder au règlement afin de régulariser votre réservation."
        if (reservation.price_cancel) {
          this.card.desciption.price = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(reservation.price_cancel)
          this.card.priceCancel = reservation.price_cancel
        }else{
          this.isCardModalActive=false
          if (confirm("ATTENTION ! Vous allez annuler la réservation du bateau du skipper "+reservation.user.first_name+" du "+reservation.date_begin+" au "+reservation.date_end)) {
            this.setOrderStatus(reservation.order_id, reservation.status_slug, 'cancel')
          }
        }
      }
      if (this.isCardModalActive) {
        setTimeout(() => { this.card.form.mount('#jsmb-stripe-card') }, 50);
      }
    },
    Stripe_addUpdateCard (orderId) {
      this.card.buttonDoneLoad = true
      if (!this.card.isCancel) {
        const card = this.stripe.createToken(this.card.form);
        card.then((respCard) => {
          this.setOrderStatus(orderId, this.card.order_status_slug, "confirm", respCard.token.id)
        })
      }else{
        const paymentIntent = this.stripe.handleCardPayment(this.card.client_secret, this.card.form);
        paymentIntent.then(
          resp => {
            if (resp.paymentIntent.status === "succeeded") {
              this.setOrderStatus(orderId, this.card.order_status_slug, "cancel", null, this.card.priceCancel)
            }else{
              this.isCardModalActive = false
              this.card.buttonDoneLoad = false
            }
          }
        ).catch(
          error => {
            this.isCardModalActive = false
            this.card.buttonDoneLoad = false
          }
        )
      }
    },
    Stripe_cancelOrder (reservation) {
      this.buttonCancelLoad.waitPay = true
      this.buttonCancelLoad.confirm = true
      if (reservation.price_cancel) {
        this.$store.dispatch(
          STRIPE_CANCEL_ORDER, { orderId: reservation.order_id, priceCancel: reservation.price_cancel }
        ).then(
          resp => {
            this.card.client_secret = resp.client_secret
            this.Stripe_initFormCard(reservation, true)
          }
        )
      }else{
        if (confirm("ATTENTION ! Vous allez annuler la réservation du bateau du skipper "+reservation.user.first_name+" du "+reservation.date_begin+" au "+reservation.date_end)) {
          this.setOrderStatus(reservation.order_id, reservation.status_slug, 'cancel')
        }
      }
    },
    actionInvoice(invoice) {
      switch (invoice.status_slug) {
        case "unpaid":
          window.open(invoice.stripe_payment_url, '_blank').focus();
          window.location = '/mon-compte'
          break;
      
        case "paid":
          window.open(invoice.stripe_pdf_url, '_blank')
          break;
      }
    }
  },
  watch: {
    userType() {
      // Get Order
      this.$store.dispatch(
        GET_ORDER, { userType: this.userType }
      ).then(
        resp => {
          this.getOrdersResult(resp)
        }
      )
    },
    isCardModalActive(value) {
      if (!value) {
        this.card = {
          title: '',
          desciption: {
            day: '',
            resume: '',
            price: '',
          },
          form: null,
          error: "",
          orderId: null,
          buttonDoneLoad: false,
          isCancel: false,
          priceCancel: '',
          client_secret: '',
          order_status_slug: '',
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .jsmb-account-information-tab {
    padding-top:0 !important
  }
  .jsmb-account-commentaires .tabs li {
    border-top: 15px solid rgba(0,0,0,0);  
  }
  .jsmb-account-commentaires .tabs li.is-active {
    border-bottom-width: 0;
    border-top: 15px solid #14acc2;
  }
  .jsmb-account-commentaires .tabs ul {
    border-bottom: 0;
    justify-content: space-around;
  }
  .jsmb-account-commentaires .tabs a {
    text-transform: uppercase;
    padding-top: 2rem;
    font-weight: 100;
    border-bottom: 0;
  }
  .message-looper,
  .reservation-expanded{
    background:rgba(255,255,255,1);
    padding: 0.8rem;
    margin-bottom: 0;
  }
  .btn-annonce {
    margin-bottom: 1rem;
  }
  .btn-annonce .button {
    border-top-left-radius:0;
    border-top-right-radius:0;
    margin-right: 1.5rem;
  }
  .tags.has-addons {
    justify-content: center;
  }
  .is-image-centered {
    margin: 0 auto;
  }
  strong {
    margin-bottom: 1rem;
  }
  .reservation-expanded .columns {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .looper-expanded {
    border-bottom: 4px solid #eee 
  }
  .add-margin-bottom {
    margin-bottom: 1rem;
  }
  .b-table .table td {
    font-size: 14px;
    vertical-align: middle;
  }
  .b-table .table th {
      font-weight: 500;
      vertical-align: middle;
  }
  .b-table .table {
    font-weight: 300;
  }
  .table .button,
  .tag:not(body) {
    font-weight: 400;
  }

/* A SUPPRIMER */
  /*.jsmb-reservation {
    background-color: #ffffff;
    padding:1rem;
    margin-bottom:1rem;
  }
  .jsmb-place-order {
    color:#0ec0b6;
    text-transform: uppercase;
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .jsmb-reservation-prix p{
    font-weight: bold;
    text-transform: uppercase;
  }
  strong {
    display: block;
    text-transform: uppercase;
  }
  strong:last-of-type {
    margin-bottom: 1rem;
  }
  .button {
    margin-bottom: 1rem;
    margin-right: 1.5rem;
  }
  .card-content {
    background: #F2F5FB
  }
  @media screen and (max-width:798px){
    .message-looper {
      margin-bottom: 2rem;
    }
    .is-horizontal-center {
      margin:0rem auto 1rem
    }
    .jsmb-reservation-prix,
    .jsmb-message-text {
      margin-top:1rem;
    } 
  }*/
</style>